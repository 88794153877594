function SubmitFormController(jobSubmit, Uploader, genomeFactory, userProfile,
$scope, $log, $mdDialog, $location, $http, $timeout, $document, $q) {
  this.setState = () => {
    this.formState = jobSubmit.state;
    this.parentJob = jobSubmit.parentJob;

    if (!jobSubmit.hasEmail() ) {
      jobSubmit.setEmail(userProfile.user.email);
    }
  };

  this.$onInit = () => {
    this.uploader = Uploader;
    this.genomes = genomeFactory.genomes;

    // this.messages = { email: { searching: false } };
    if (!jobSubmit.hasParentJob() ) {
      jobSubmit.instanceParentJob();
    }

    this.setState();
  };

  this.resetEmailState = function resetEmailState() {
    jobSubmit.state.emailSet = false;
  };

  this.resetOptionsState = function resetOptionsState() {
    jobSubmit.state.optionsSet = false;
  };

  this.markOptionsSet = function markOptionsSet() {
    jobSubmit.state.optionsSet = true;
  };

  this.markEmailSet = function markEmailSet() {
    jobSubmit.state.emailSet = true;
  };

  this.reset = () => {
    if (!jobSubmit.parentJob.submitted && jobSubmit.parentJob.optionsSet) {
      // one step back
      jobSubmit.clearProgress();
      return;
    }

    jobSubmit.instanceParentJob();

    this.setState();
  };

  // Object.defineProperty(this, 'uploadsDone', {
  //   get: function getUploadsDone() {
  //     return uploadFactory.allDone;
  //   },
  // });

  $scope.$on(Uploader.allUploadedEvent, (event, jobs) => {
    if(!jobs) {
      // Upload was cancelled
      return;
    }

    const lastJob = jobs[jobs.length - 1];

    this.reset();

    $location.path('/queue/all').search({_id : lastJob._id});
  });

}

angular.module( 'sq.jobs.forms.submitForm.component',
['sq.jobs.upload.component', 'sq.jobs.submit.service', 'sq.jobs.forms.services.genome',
'sq.jobs.upload.service', 'sq.user.profile.service'])
.component('sqJobSubmitForm', {
  bindings: {},
  templateUrl: 'jobs/submit-form/jobs.submit-form.tpl.html',
  controller: SubmitFormController,
});
